<template>
    <div class="bg-white">
        <!-- Header -->
        <header class="absolute inset-x-0 top-0 z-50">
            <nav class="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                <div class="flex lg:flex-1">
                    <a href="https://app.ecomy.com.br/register" class="-m-1.5 p-1.5">
                        <span class="sr-only">Ecomy</span>
                        <img class="h-12 w-auto" src="../public/ecomy-logo.png" alt="" />
                    </a>
                </div>
                <div class="flex lg:hidden">
                    <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = true">
                        <span class="sr-only">Open main menu</span>
                        <Bars3Icon class="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div class="hidden lg:flex lg:gap-x-12">
                    <a
                        v-for="item in navigation"
                        :key="item.name"
                        :href="item.href"
                        class="text-sm font-semibold leading-6 text-gray-900">{{ item.name }}</a>
                </div>
                <div class="hidden lg:flex lg:flex-1 lg:justify-end">
                    <a href="https://app.ecomy.com.br/register" class="text-sm font-semibold leading-6 text-gray-900">Cadastre-se <span aria-hidden="true">&rarr;</span></a>
                </div>
            </nav>
            <Dialog class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
                <div class="fixed inset-0 z-50" />
                <DialogPanel class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div class="flex items-center justify-between">
                        <a href="#" class="-m-1.5 p-1.5">
                            <span class="sr-only">Ecomy</span>
                            <img class="h-8 w-auto" src="../public/ecomy-logo.png" alt="" />
                        </a>
                        <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = false">
                            <span class="sr-only">Close menu</span>
                            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div class="mt-6 flow-root">
                        <div class="-my-6 divide-y divide-gray-500/10">
                            <div class="space-y-2 py-6">
                                <a v-for="item in navigationHead" :key="item.name" :href="item.href" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">{{ item.name }}</a>
                            </div>
                            <div class="py-6">
                                <a href="https://app.ecomy.com.br/register"
                                   class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    Cadastra-se
                                </a>
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </header>
    </div>
    <div class="bg-white">
        <main class="isolate">
            <!-- Hero section -->
            <div class="relative">
                <div class="py-24 sm:py-32">
                    <div class="mx-auto max-w-7xl px-6 lg:px-8">
                        <div class="mx-auto max-w-2xl text-center">
                            <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                                Aumente seu Faturamento com Ecomy
                            </h1>
                            <p class="mt-6 text-lg leading-8 text-gray-600">
                                Descubra como nossos aplicativos podem transformar suas vendas e levar seu e-commerce ao próximo nível. Ferramentas inteligentes para decisões mais assertivas e resultados superiores.
                            </p>
                            <div class="mt-10 flex items-center justify-center gap-x-6">
                                <a href="https://app.ecomy.com.br/register" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                    CRIAR MINHA CONTA
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Parceiros -->
            <div id="parceiros" class="mx-auto max-w-7xl px-6 lg:px-8">
                <div class="mx-auto max-w-2xl lg:text-center">
                    <h2 class="text-base font-semibold leading-7 text-indigo-600">Parceiros</h2>
                    <img class="col-span-2 max-h-24 w-full object-contain sm:col-start-2 lg:col-span-1"
                         src="../public/nuvemshop-logo.png"
                         alt="NuvemShop"
                         width="500"
                         height="300"
                    />
                </div>
            </div>

            <div id="aplicativos" class="mx-auto max-w-7xl px-6 lg:px-8">
                <div class="mx-auto max-w-2xl lg:text-center">
                    <h2 class="mb-6 text-base font-semibold leading-7 text-indigo-600">Aplicativos</h2>
                    <div class="flex justify-center space-x-4">
                        <a href="https://desh.app.br">
                            <img class="max-h-24 object-contain"
                                 src="../public/desh-logo.png"
                                 alt="NuvemShop"
                                 width="300"
                                 height="200"
                            />
                        </a>

                        <a href="https://kitify.com.br">
                            <img class="max-h-24 object-contain"
                                 src="../public/kitify-logo.png"
                                 alt="NuvemShop"
                                 width="300"
                                 height="200"
                            />
                        </a>
                    </div>
                </div>
            </div>



            <!-- Funcionalidades -->
            <div id="funcionalidades" class="mx-auto mt-24 max-w-7xl px-6 lg:px-8">
                <div class="mx-auto max-w-2xl lg:text-center">
                    <h2 class="text-base font-semibold leading-7 text-indigo-600">Funcionalidades</h2>
                    <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Ferramentas Poderosas para Alavancar seu Negócio</p>
                    <p class="mt-6 text-lg leading-8 text-gray-600">Com os aplicativos Ecomy, você terá acesso a insights valiosos que vão transformar a forma como você gerencia suas vendas e campanhas de marketing.</p>
                </div>
                <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                    <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                        <div class="relative pl-16">
                            <dt class="text-base font-semibold leading-7 text-gray-900">
                                <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                    <!-- Ícone aqui -->
                                    <svg class="h-6 w-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <path d="M3 3h18v18H3V3z"></path>
                                        <path d="M9 3v18"></path>
                                        <path d="M3 9h18"></path>
                                    </svg>
                                </div>
                                Decisões Baseadas em Dados
                            </dt>
                            <dd class="mt-2 text-base leading-7 text-gray-600">Utilize nossos relatórios para tomar decisões informadas que aumentam a eficiência e maximizam seus lucros.</dd>
                        </div>

                        <div class="relative pl-16">
                            <dt class="text-base font-semibold leading-7 text-gray-900">
                                <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                    <!-- Ícone aqui -->
                                    <svg class="h-6 w-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                        <polyline points="7 10 12 15 17 10"></polyline>
                                        <line x1="12" y1="15" x2="12" y2="3"></line>
                                    </svg>
                                </div>
                                Insights de Mercado
                            </dt>
                            <dd class="mt-2 text-base leading-7 text-gray-600">Compreenda melhor o comportamento do consumidor e adapte suas estratégias de marketing para capturar mais vendas.</dd>
                        </div>

                        <div class="relative pl-16">
                            <dt class="text-base font-semibold leading-7 text-gray-900">
                                <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                    <!-- Ícone aqui -->
                                    <svg class="h-6 w-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <circle cx="12" cy="12" r="10"></circle>
                                        <line x1="14.31" y1="8" x2="20.05" y2="17.94"></line>
                                        <line x1="9.69" y1="8" x2="21.17" y2="8"></line>
                                        <line x1="7.38" y1="12" x2="13.12" y2="2.06"></line>
                                        <line x1="9.69" y1="16" x2="3.95" y2="6.06"></line>
                                        <line x1="14.31" y1="16" x2="2.83" y2="16"></line>
                                        <line x1="16.62" y1="12" x2="10.88" y2="21.94"></line>
                                    </svg>
                                </div>
                                Eficiência Operacional
                            </dt>
                            <dd class="mt-2 text-base leading-7 text-gray-600">Melhore a eficiência operacional do seu e-commerce com ferramentas que automatizam processos e economizam tempo.</dd>
                        </div>

                        <div class="relative pl-16">
                            <dt class="text-base font-semibold leading-7 text-gray-900">
                                <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                    <!-- Ícone aqui -->
                                    <svg class="h-6 w-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <path d="M3 3h18v18H3V3z"></path>
                                        <path d="M9 3v18"></path>
                                        <path d="M3 9h18"></path>
                                    </svg>
                                </div>
                                Integração com Principais Plataformas
                            </dt>
                            <dd class="mt-2 text-base leading-7 text-gray-600">Nossos aplicativos se integram facilmente com Nuvemshop, Shopify e outras plataformas líderes de e-commerce, proporcionando uma experiência de uso fluida.</dd>
                        </div>
                    </dl>
                </div>
            </div>

            <!-- Stats -->
            <div id="clientes" class="bg-white py-24">
                <div class="mx-auto max-w-7xl px-6 lg:px-8">
                    <div class="mx-auto max-w-2xl lg:max-w-none">
                        <div class="text-center">
                            <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Confiado por lojistas em todo o mundo</h2>
                            <p class="mt-4 text-lg leading-8 text-gray-600">Lojistas que utilizam nossos aplicativos relatam um aumento médio de 30% no faturamento. Não perca a oportunidade de impulsionar suas vendas.</p>
                        </div>
                        <dl class="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
                            <div class="flex flex-col bg-gray-400/5 p-8">
                                <dt class="text-sm font-semibold leading-6 text-gray-600">Aumento Médio de Faturamento</dt>
                                <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900">30%</dd>
                            </div>
                            <div class="flex flex-col bg-gray-400/5 p-8">
                                <dt class="text-sm font-semibold leading-6 text-gray-600">Satisfação dos Usuários</dt>
                                <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900">95%</dd>
                            </div>
                            <div class="flex flex-col bg-gray-400/5 p-8">
                                <dt class="text-sm font-semibold leading-6 text-gray-600">Integrações</dt>
                                <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900">+50</dd>
                            </div>
                            <div class="flex flex-col bg-gray-400/5 p-8">
                                <dt class="text-sm font-semibold leading-6 text-gray-600">Lojas Atendidas</dt>
                                <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900">+1000</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>

            <!-- CTA section -->
            <div class="relative -z-10 mt-24 px-6 lg:px-8">
                <div class="mx-auto max-w-2xl text-center">
                    <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Aumente sua produtividade.<br />Comece a usar nosso app hoje.</h2>
                    <p class="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">Não perca mais tempo. Comece a usar nosso aplicativo hoje e veja como ele pode aumentar o faturamento da sua loja. Clique no botão abaixo para começar.</p>
                    <div class="mt-10 flex items-center justify-center gap-x-6">
                        <a href="https://app.ecomy.com.br/register" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">ASSINE AGORA</a>
                        <a href="https://app.ecomy.com.br/register" class="text-sm font-semibold leading-6 text-gray-900">Saiba Mais <span aria-hidden="true">→</span></a>
                    </div>
                </div>
            </div>
        </main>


        <!-- Footer -->
        <div class="mx-auto mt-24">
            <footer aria-labelledby="footer-heading" class="relative border-t border-gray-900/10">
                <div class="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
                    <nav class="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12" aria-label="Footer">
                        <div v-for="item in navigation" :key="item.name" class="pb-6">
                            <a :href="item.href" class="text-sm leading-6 text-gray-600 hover:text-gray-900">{{ item.name }}</a>
                        </div>
                    </nav>
                    <p class="mt-10 text-center text-xs leading-5 text-gray-500">&copy; 2024 Ecomy, Inc. All rights reserved.</p>
                </div>
            </footer>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { Dialog, DialogPanel } from '@headlessui/vue'
import {
    Bars3Icon,

    XMarkIcon,
} from '@heroicons/vue/24/outline'

const navigation = [
    { name: 'Parceiros', href: '#parceiros' },
    { name: 'Funcionalidades', href: '#funcionalidades' },
    { name: 'Clientes', href: '#clientes' },
]



const mobileMenuOpen = ref(false)
</script>